export const emailSettings = {
  serviceId: "service_06bstxj",
  appName: "Meal Chef AI",
  templateId: "template_t5bzvjj",
  userId: "gMK8i-j4OzeqL96lQ",
  accessToken: "FfbkGn9UEmaGVZBkpTcdX",
};

/*
  DOCUMENTATION

  The contact form uses EmailJS to send emails. All you need to do is signup to EmailJS at https://www.emailjs.com/
  and in your new account you will find the details to complete the above.

*/
