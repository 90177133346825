import { Heading, Paragraph, SubHeading, Caption, BulletList, ParagraphSlant, SectionWrapper, NumberedList, SubHeading2 } from "../styles/base";

export default function PrivacyPolicyText() {
  return (
    <>
      <SectionWrapper>
      <SubHeading>Privacy Policy for Meal Chef AI</SubHeading>
      <ParagraphSlant>Last Updated: November 27, 2024</ParagraphSlant>

      <SubHeading2>Introduction</SubHeading2>
      <Paragraph>
        Welcome to Meal Chef AI ("we," "our," or "us"), a mobile application developed and operated by Gwinyai Nyatsoka. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application Meal Chef AI (the "App"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the App.
      </Paragraph>

      <SubHeading2>Information We Collect</SubHeading2>
      <SubHeading2>Personal Information</SubHeading2>
      <Paragraph>
        We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device ("personal information"):
      </Paragraph>
      <BulletList>
        <li>Account Information: When you sign up using Google or Apple Sign-In, we receive your email address and account identifier</li>
        <li>User Content: Photos you take using the App's camera feature for meal analysis</li>
        <li>Device Information: Device identifier, operating system, and version</li>
        <li>Usage Data: How you interact with our App, including meal preferences and generated suggestions</li>
      </BulletList>

      <SubHeading2>How We Collect Information</SubHeading2>
      <BulletList>
        <li>Direct collection through your input</li>
        <li>Automatic collection through App usage</li>
        <li>Third-party services (Google and Apple Sign-In)</li>
        <li>Device camera access for food photography</li>
      </BulletList>

      <SubHeading2>Use of Your Information</SubHeading2>
      <Paragraph>We use the information we collect to:</Paragraph>
      <BulletList>
        <li>Provide and maintain our Service</li>
        <li>Generate meal suggestions using AI technology</li>
        <li>Analyze food photos to provide meal recommendations</li>
        <li>Authenticate users and maintain account security</li>
        <li>Improve our AI algorithms and App functionality</li>
        <li>Comply with legal obligations</li>
        <li>Communicate with you about App updates or changes</li>
      </BulletList>

      <SubHeading2>Account Deactivation and Deletion</SubHeading2>
      <Paragraph>You can deactivate or delete your account at any time:</Paragraph>
      <NumberedList>
        <li>Access App Settings</li>
        <li>Select Account Management</li>
        <li>Choose "Deactivate Account" or "Delete Account"</li>
        <li>Follow the confirmation process</li>
      </NumberedList>

      <SubHeading2>Your Rights Under Data Protection Laws</SubHeading2>
      
      <ParagraphSlant>GDPR Rights (EU Users)</ParagraphSlant>
      <Paragraph>If you are a resident of the European Economic Area (EEA), you have certain data protection rights:</Paragraph>
      <BulletList>
        <li>Right to access</li>
        <li>Right to rectification</li>
        <li>Right to erasure</li>
        <li>Right to restrict processing</li>
        <li>Right to data portability</li>
        <li>Right to object</li>
        <li>Rights related to automated decision-making</li>
      </BulletList>

      <ParagraphSlant>CCPA Rights (California Residents)</ParagraphSlant>
      <Paragraph>If you are a California resident, you have the right to:</Paragraph>
      <BulletList>
        <li>Request disclosure of personal information we collect about you</li>
        <li>Request deletion of your personal information</li>
        <li>Opt-out of the sale of personal information</li>
        <li>Non-discrimination for exercising CCPA rights</li>
      </BulletList>

      <ParagraphSlant>PIPEDA Rights (Canadian Users)</ParagraphSlant>
      <Paragraph>Under PIPEDA, you have the right to:</Paragraph>
      <BulletList>
        <li>Access your personal information</li>
        <li>Challenge the accuracy of your information</li>
        <li>Withdraw consent</li>
        <li>File a complaint about our privacy practices</li>
      </BulletList>

      <SubHeading2>Third-Party Services</SubHeading2>
      <Paragraph>Our App uses third-party services that may collect information about you:</Paragraph>
      <BulletList>
        <li>Google Sign-In</li>
        <li>Apple Sign-In</li>
        <li>AI Image Generation Services</li>
        <li>AI Analysis Services</li>
      </BulletList>

      <SubHeading2>Contact Us</SubHeading2>
      <Paragraph>
        If you have any questions about this Privacy Policy or our practices, please contact us at:
      </Paragraph>
      <Paragraph>
        Gwinyai Nyatsoka<br />
        gnyatsoka@gmail.com<br />
      </Paragraph>

      <SubHeading2>CalOPPA Compliance</SubHeading2>
      <BulletList>
        <li>Users can visit our site anonymously</li>
        <li>Our Privacy Policy link includes the word 'Privacy'</li>
        <li>Users will be notified of Privacy Policy changes</li>
        <li>Users can change their personal information by logging into their account</li>
      </BulletList>

      <SubHeading2>Cookie Policy</SubHeading2>
      <Paragraph>
        Our App does not use cookies directly, but third-party services may use similar technologies for authentication and functionality.
      </Paragraph>

      <SubHeading2>Consent</SubHeading2>
      <ParagraphSlant>
        By using our App, you consent to our Privacy Policy and agree to its terms. If you disagree with this policy, please do not use our App.
      </ParagraphSlant>
      </SectionWrapper>
    </>
  );
}

/*
Create your privacy policy here. Use <Heading> tags to create headings. Use <SubHeading> to create a subheading. Use <Paragraph> to create a paragraph.
*/
