import { Heading, Paragraph, SubHeading, Caption, BulletList, ParagraphSlant, SectionWrapper, NumberedList, SubHeading2 } from "../styles/base";

export default function TermsText() {
  return (
    <>
      <SubHeading>Terms and Conditions for Meal Chef AI</SubHeading>
      <ParagraphSlant>Last Updated: November 27, 2024</ParagraphSlant>

      <SubHeading2>1. Agreement to Terms</SubHeading2>
      <Paragraph>
        By downloading, installing, or using Meal Chef AI ("the App"), you agree to these Terms and Conditions. If you disagree with any part, do not use the App.
      </Paragraph>

      <SubHeading2>2. Definitions</SubHeading2>
      <BulletList>
        <li>"App" refers to Meal Chef AI</li>
        <li>"User" refers to any person who accesses or uses the App</li>
        <li>"Content" refers to all text, images, information, and other materials generated or provided through the App</li>
        <li>"User Content" refers to any photos or information uploaded by users</li>
      </BulletList>

      <SubHeading2>3. App License</SubHeading2>
      <Paragraph>
        Meal Chef AI grants you a limited, non-exclusive, non-transferable, revocable license to use the App for personal, non-commercial purposes.
      </Paragraph>

      <SubHeading2>4. AI-Generated Content</SubHeading2>
      
      <ParagraphSlant>4.1 Accuracy Disclaimer</ParagraphSlant>
      <Paragraph>The App uses artificial intelligence to generate meal suggestions and images. While we strive for accuracy, we cannot guarantee:</Paragraph>
      <BulletList>
        <li>The accuracy of AI-generated suggestions</li>
        <li>The nutritional accuracy of meal recommendations</li>
        <li>The feasibility or safety of suggested recipes</li>
        <li>The authenticity of AI-generated images</li>
      </BulletList>

      <ParagraphSlant>4.2 User Responsibility</ParagraphSlant>
      <Paragraph>Users are solely responsible for:</Paragraph>
      <BulletList>
        <li>Verifying the safety and appropriateness of suggested meals</li>
        <li>Ensuring they have no allergies to suggested ingredients</li>
        <li>Following proper food safety guidelines</li>
        <li>Making informed decisions about following AI suggestions</li>
      </BulletList>

      <SubHeading2>5. User Content and Privacy</SubHeading2>
      
      <ParagraphSlant>5.1 Photo Uploads</ParagraphSlant>
      <Paragraph>By uploading photos to the App, you:</Paragraph>
      <BulletList>
        <li>Retain ownership of your content</li>
        <li>Grant us license to analyze and process your photos</li>
        <li>Agree that photos may be used to improve our AI systems</li>
        <li>Understand that deleted accounts will result in photo deletion</li>
      </BulletList>

      <ParagraphSlant>5.2 Authentication</ParagraphSlant>
      <Paragraph>Users must authenticate using Google or Apple accounts and:</Paragraph>
      <BulletList>
        <li>Provide accurate authentication information</li>
        <li>Maintain the security of their login credentials</li>
        <li>Accept our Privacy Policy terms</li>
      </BulletList>

      <SubHeading2>6. Prohibited Uses</SubHeading2>
      <BulletList>
        <li>Modify, reverse engineer, or decompile the App</li>
        <li>Upload harmful or malicious content</li>
        <li>Use the App for illegal purposes</li>
        <li>Share access with other users</li>
        <li>Attempt to circumvent security measures</li>
        <li>Use the App to harass or harm others</li>
      </BulletList>

      <SubHeading2>7. Disclaimers and Limitations of Liability</SubHeading2>
      
      <ParagraphSlant>7.1 Service Availability</ParagraphSlant>
      <Paragraph>We provide the App "as is" and do not guarantee:</Paragraph>
      <BulletList>
        <li>Uninterrupted service</li>
        <li>Error-free operation</li>
        <li>Continuous availability of AI features</li>
        <li>Backup of user data</li>
      </BulletList>

      <ParagraphSlant>7.2 Liability Limitations</ParagraphSlant>
      <Paragraph>Gwinyai Nyatsoka and Meal Chef AI shall not be liable for:</Paragraph>
      <BulletList>
        <li>Direct, indirect, or consequential damages</li>
        <li>Health issues arising from following suggestions</li>
        <li>Allergic reactions or food safety incidents</li>
        <li>Data loss or security breaches</li>
        <li>Costs incurred from using the App</li>
        <li>Issues arising from third-party services</li>
      </BulletList>

      <SubHeading2>8. Indemnification</SubHeading2>
      <Paragraph>Users agree to indemnify and hold harmless Gwinyai Nyatsoka, Meal Chef AI, and its affiliates from:</Paragraph>
      <BulletList>
        <li>Claims arising from App usage</li>
        <li>Violations of these terms</li>
        <li>User content uploads</li>
        <li>Interactions with AI-generated content</li>
        <li>Any resulting legal fees</li>
      </BulletList>

      <SubHeading2>9. Modifications</SubHeading2>
      <Paragraph>We reserve the right to:</Paragraph>
      <BulletList>
        <li>Modify these terms at any time</li>
        <li>Change App functionality</li>
        <li>Adjust or remove features</li>
        <li>Update pricing</li>
        <li>Terminate service</li>
      </BulletList>

      <SubHeading2>10. Contact Information</SubHeading2>
      <Paragraph>
        For questions about these Terms, contact:
        Gwinyai Nyatsoka
        gnyatsoka@gmail.com
      </Paragraph>

      <SubHeading2>11. Agreement</SubHeading2>
      <ParagraphSlant>
        By using the App, you acknowledge having read and understood these terms and agree to be bound by them.
      </ParagraphSlant>
    </>
  );
}

/*
Create your terms an conditions here. Use <Heading> tags to create headings. Use <SubHeading> to create a subheading. Use <Paragraph> to create a paragraph.
*/
